<template>
  <v-row wrap no-gutters>
    <credentials-select
      :key="`${step.id}-credentialname`"
      step-type="S3"
      :properties="properties"
      step-label="s3"
      :readonly="!canEdit"
      :required="false"
      @change="handleChange('credentialName', $event)"
    />

    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedS3Actions"
        :default-value="properties.action"
        step-label="s3"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.fields.targetObject"
        outlined
        dense
        :label="$lang.labels.targetObject"
        :required="isTargetObjectRequired"
        :rules="[
          isTargetObjectRequired ? (v => !!v || $lang.labels.required) : () => true, 
          (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong
        ]"
        :readonly="!canEdit"
        :class="isTargetObjectRequired && 'required-asterisk'"
      />
    </v-col>

    <v-col
      v-if="['READ', 'DELETE', 'CREATE'].includes(properties.action)"
      cols="12"
    >
      <v-text-field
        :key="`${step.id}-key`"
        v-model="properties.fields.key"
        outlined
        dense
        :label="$lang.labels.key"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
      ></v-text-field>
    </v-col>

    <v-col
      v-if="properties.action === 'CREATE'"
      cols="12"
    >
      <v-text-field
        :key="`${step.id}-content`"
        v-model="properties.fields.content"
        outlined
        dense
        :label="$lang.labels.content"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
      ></v-text-field>
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-path`"
        v-model="properties.fields.path"
        outlined
        dense
        :label="$lang.labels.path"
        :readonly="!canEdit"
      />
    </v-col>
  </v-row>
</template>

<script>
import {
  ActionSelect,
  CredentialsSelect
} from './components'
import StepMixin from './StepMixin'

import { S3_ACTIONS } from './constants'

export default {
  name: 'S3Step',
  components: {
    ActionSelect,
    CredentialsSelect
  },
  mixins: [StepMixin],
  computed: {
    formattedS3Actions() {
      return S3_ACTIONS.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    isTargetObjectRequired() {
      return ['READ', 'LIST'].includes(this.properties.action)
    }
  }
}
</script>
